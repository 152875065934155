'use client';
import { createSlice } from '@reduxjs/toolkit';

import { initialState } from '@/Redux/Slices/HeaderSlice/states';

//--------------------------------------------------------------
export const HeaderState = createSlice({
	name: 'HeaderState',
	initialState,
	reducers: {
		SetTheme(state) {
			if (state.theme === 'WHITE') {
				state.theme = 'BLACK';
			} else if (state.theme === 'BLACK') {
				state.theme = 'WHITE';
			}
		},

		SetFonts(state, action) {
			if (action.payload === '+' && state.CurrentFontSize < 3) {
				state.CurrentFontSize = state.CurrentFontSize + 1;
			}
			if (action.payload === '-' && state.CurrentFontSize >= 1) {
				state.CurrentFontSize = state.CurrentFontSize - 1;
			}
		},
		SetCurrentPagePC(state, action) {
			state.currentPagePC = action.payload;
		},
		SetCurrentTagsId(state, action) {
			state.currentTagsId = action.payload;
		},
	},
});

export default HeaderState.reducer;
